/************
 * Developed by : Shiva Software Solutions
 * Date : 21-06-2021
 * Descriptions : Login AND Signup Functionality
 * Dependicies : To use JWT Authendication token
 ************/

import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_AUTH_TOKEN,
  SET_MOBILENO,
  AUTH_HOSPITAL,
  AUTH_CLIENT_ROOM,
  AUTH_CLIENT_FLOOR,
  SET_PASWD,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  OTP_SUCCESS,
  OTP_SUCCESS_MSG,
  OTP_ERROR,
  OTP_SIGNUP_ERROR,
  SIGNIN_ERROR,
  PATIENT_COMPLETED_COUNT,
  PATIENT_COMPLETED_DATA,
  OTP_UPDTE_ERROR,
  OTP_UPDTE_SUCCESS,
  VENDOR_API_DATA,
} from '../../shared/constants/ActionTypes';
import {checkAuthentication} from '../../redux/actions';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  onWriteCloudWatchLog,
} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {useSelector} from 'react-redux';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'JWTAuth.js';

export const onJwtUserSignUp = ({email, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};
export const onGetOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getOtpDoctor', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_SUCCESS,
              payload: true,
            });
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetUpdateOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getOtpUpdateDoctor', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_UPDTE_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_UPDTE_SUCCESS,
              payload: true,
            });
            dispatch({
              type: OTP_UPDTE_ERROR,
              payload: '',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUpdateOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetUpdateOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
//Forgot password OTP function
export const onGetForgotpaswdOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/vendor/getForgotPasswordOtpVendor', value)
        .then((res) => {
          let err_text = 'This Email ID. does not exists in our records';
          if (value && value['isNumber']) {
            err_text = 'This Email ID. does not exists in our records';
          } else {
            err_text = `This Email ID doesn't exists in our records`;
          }
          if (res.hasOwnProperty.call(res.data, 'body')) {
            if (res.data.body === 'OTP SENT') {
              dispatch({
                type: OTP_ERROR,
                payload: '',
              });
              dispatch({
                type: OTP_SUCCESS,
                payload: true,
              });
              dispatch({
                type: OTP_SUCCESS_MSG,
                payload: res.data.message,
              });
            } else {
              dispatch({
                type: OTP_ERROR,
                payload: err_text,
              });
            }
          } else {
            if (res.data.message.includes('Error')) {
              dispatch({
                type: OTP_ERROR,
                payload: res.data.message.split(':')[1],
              });
            }
            dispatch({
              type: OTP_ERROR,
              payload: err_text,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetForgotpaswdOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetForgotpaswdOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

export const onJwtSignIn = ({userId, userPaswd}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userPaswd};
    try {
      await jwtAxios
        .post('v1/vendor/loginjwt', body)
        .then((res) => {
          if (res.data.hasOwnProperty.call(res.data, 'message')) {
            if (res.data.message.includes('Error')) {
              dispatch({
                type: SIGNIN_ERROR,
                payload:
                  res.data.message?.split(':')[1]?.trim() ||
                  'Something went wrong',
              });
            }
          } else {
            localStorage.setItem('token', res.data.body.token);
            dispatch(setJWTToken(res.data.body.token));
            dispatch(setMobileNbr(userId));
            dispatch(setPaswd(userPaswd));
            var body = {userId, userPaswd, jwtToken: res.data.body.token};
            dispatch(loadJWTUser(body));
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message.split(':')[1].trim(),
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJwtSignIn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJwtSignIn', '0', err),
      );
    }
  };
};

export const loadJWTUser = (body) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      await jwtAxios
        .post('v1/vendor/login', body)
        .then((res) => {
          if (res.status === 200) {
            var response = res?.data?.body?.userDet[0];
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                media: response.media,
                authType: AuthType.JWT_AUTH,
                displayName: response.userId,
                credentialid: response.credentialId,
                clientName: response.clientName,
                clientid: response.clientId,
                billing: response.billing,
                token: '',
                roleId: response.userCategoryId,
                role:
                  response.userCategoryId == 1 || response.userCategoryId == 2
                    ? 'Vendor'
                    : response.categoryName,
                credentialId: response.credentialId,
                userCategory: response.categoryName,
                doctorID: response.doctorID,
                vaccineReminder: response.vaccineReminder || false,
              },
            });
            dispatch({
              type: AUTH_HOSPITAL,
              payload: res?.data?.body?.hospitalDet,
            });
            dispatch({
              type: AUTH_CLIENT_FLOOR,
              payload: res?.data?.body?.floorDet,
            });
            dispatch({
              type: AUTH_CLIENT_ROOM,
              payload: res?.data?.body?.roomDet,
            });
          } else {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('1')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'EmailID. or password is incorrect',
                });
              }
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'This EmailId. is not registered with us',
                });
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: UPDATE_AUTH_USER, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'loadJWTUser',
              '0',
              error,
            ),
          );
          return;
        });
    } catch (err) {
      dispatch(fetchError(err.response.error));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'loadJWTUser', '0', err),
      );
      return;
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const setMobileNbr = (number) => {
  return async (dispatch) => {
    dispatch({
      type: SET_MOBILENO,
      payload: number,
    });
  };
};

export const setPaswd = (paswd) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PASWD,
      payload: paswd,
    });
  };
};

export const onJWTAuthSignout = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      setTimeout(() => {
        dispatch({type: SIGNOUT_AUTH_SUCCESS});
        dispatch({type: FETCH_SUCCESS});

        localStorage.removeItem('token');
        localStorage.clear();
        window.localStorage.clear();
      }, 500);
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};

export const signOutReset = (value, initialPath, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      // dispatch({type: SIGNOUT_AUTH_SUCCESS});
      // dispatch({type: FETCH_SUCCESS});

      localStorage.removeItem('token');
      localStorage.clear();
      window.localStorage.clear();
      // after logout reset password screen
      // after that move to reset password page
      let data = initialPath.split('/')[2];
      if (data) {
        let passData = {
          value: data,
        };
        dispatch(checkAuthentication(passData, history));
      } else {
        history.push('/error-pages/error-404');
      }
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};
